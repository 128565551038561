import { Trans } from "@lingui/macro";
import { useLocaleContext } from "../lib/localeContext";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { firestoreDB } from "../lib/firebase-rt-db";
import { stateRoutes } from "..";
import rgbHex from "rgb-hex";

interface IBannerProps {
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

type Locale = {
  [key: string]: string;
  en: string;
  ms: string;
  zh: string;
};

type TPrn2023Banner = {
  id: string;
  bannerPath: string;
  code: string;
  color: string;
  show: Boolean;
  subtitle: Locale;
  title: Locale;
  titleColor: string;
  subtitleColor: string;
  chevronColor: string;
};

const Banner: React.FC<IBannerProps> = ({ setDialogOpen }) => {
  const locale = useLocaleContext();
  const location = useLocation();
  const [banner, setBanner] = useState<TPrn2023Banner | null>(null);
  const prnFirebaseColID = process.env.REACT_APP_FB_COL_ID || "prn2023";

  useEffect(() => {
    // exclude Overall as this is associated to "LANDING_TOP" on firebase
    const selectedBanner =
      stateRoutes
        .slice(1)
        .find((route) => route.path === location.pathname.split("/").pop())
        ?.path || "LANDING_TOP";
    const unsub = onSnapshot(
      doc(
        firestoreDB,
        prnFirebaseColID,
        "ui",
        "banners",
        capitalizeFirstLetterWithHypen(selectedBanner)
      ),
      (doc) => {
        const data = doc.data() as TPrn2023Banner;
        setBanner(data);
      }
    );
    return unsub;
  }, [location]);

  const capitalizeFirstLetterWithHypen = (route: string) => {
    return route
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join("-");
  };

  return (
    <>
      {banner ? (
        banner.show && (
          <div
            className="md:flex md:items-center md:justify-between mb-4 rounded-lg p-4"
            style={
              banner.color !== ""
                ? {
                    backgroundColor: `#${rgbHex(banner.color)}`,
                  }
                : { backgroundColor: "#DDE7FF" }
            }
          >
            <div className="md:w-4/5">
              <p
                className="font-sen text-lg font-bold"
                style={
                  banner.titleColor !== ""
                    ? {
                        color: `#${rgbHex(banner.titleColor)}`,
                      }
                    : { color: "#262833" }
                }
              >
                {banner.title?.[locale as string]}
              </p>
              <p
                className="font-sen text-text-darkest"
                style={
                  banner.subtitleColor !== ""
                    ? {
                        color: `#${rgbHex(banner.subtitleColor)}`,
                      }
                    : { color: "#262833" }
                }
              >
                {banner?.subtitle?.[locale as string]}
              </p>
            </div>
            <p
              className="font-sen font-bold text-lg cursor-pointer text-right mt-4 md:mt-0"
              style={
                banner.chevronColor !== ""
                  ? {
                      color: `#${rgbHex(banner.chevronColor)}`,
                    }
                  : { color: "#807F98" }
              }
              onClick={() => {
                ReactGA.event("prn2023_notifyme", {
                  page_language: locale,
                });
                setDialogOpen(true);
              }}
            >
              <Trans>Notify Me</Trans>
            </p>
          </div>
        )
      ) : (
        <div className="p-4 mb-4 rounded-lg bg-secondary flex justify-center h-[88px] items-center">
          <p className="font-poppins font-bold">
            <Trans>Loading...</Trans>
          </p>
        </div>
      )}
    </>
  );
};

export default Banner;
