import PRNSwitchToAppDialog from "./prn-switch-to-app-dialog";
import PRNPopUpDialog from "./prn-pop-up-dialog";
import OverviewSection from "./components/overview-section";
import StateViewSection from "./components/stateview-section";
import TallyViewSection from "./components/tallyview-section";
import QRContainer from "./components/qr-container";
import RightSection from "./components/right-section";
import { Trans, t } from "@lingui/macro";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ReactSEO from "./lib/react-seo";
import ReactGA from "react-ga4";
import { useLocaleContext } from "./lib/localeContext";
import { doc, onSnapshot, collection, query } from "firebase/firestore";
import { firestoreDB } from "./lib/firebase-rt-db";
import { coalitionsOrder } from "./data/coalition";

export type TCoalition = {
  id: string;
  code: string;
  color: string;
  imageUrl: string;
  partyName: string;
  stateSeatsWon: {
    [key: string]: number;
  };
  value: number;
};

type TState = {
  order: number;
  electionStatus: string;
  stateCode: string;
  stateName: string;
  stateNameLocale?: JSX.Element;
  totalSeats: number;
  winningParty: string;
  competingCoalitions: {
    [key: string]: string;
  };
};

type TStatesTab = {
  key: string;
  code?: string;
  name: JSX.Element;
  seoTitle: string;
  seoDescription: string;
};

export type TSortedState = TState & {
  value?: number;
  mappedCoalitions: TCoalition[];
};

const LandingPage = () => {
  const statesTab: TStatesTab[] = [
    {
      key: "overall",
      name: <Trans>Overall</Trans>,
      seoTitle: t`2023 State Election Live Results And Tally - Lumi News`,
      seoDescription: t`2023 Malaysian state election results and live tally for all DUN seats including Selangor, Penang, Kelantan, Terengganu, Kedah, and Negeri Sembilan.`,
    },
    {
      key: "selangor",
      code: "SEL",
      name: <Trans>Selangor</Trans>,
      seoTitle: t`2023 Selangor State Election Results - Lumi News`,
      seoDescription: t`Latest results and live tally for the 2023 Selangor State Election. See the winners and who lost in real-time.`,
    },
    {
      key: "penang",
      code: "PEN",
      name: <Trans>Penang</Trans>,
      seoTitle: t`2023 Penang State Election Results - Lumi News`,
      seoDescription: t`Latest results and live tally for the 2023 Penang State Election. See the winners and who lost in real-time.`,
    },
    {
      key: "kelantan",
      code: "KEL",
      name: <Trans>Kelantan</Trans>,
      seoTitle: t`2023 Kelantan State Election Results - Lumi News`,
      seoDescription: t`Latest results and live tally for the 2023 Kelantan State Election. See the winners and who lost in real-time.`,
    },
    {
      key: "terengganu",
      code: "TER",
      name: <Trans>Terengganu</Trans>,
      seoTitle: t`2023 Terengganu State Election Results - Lumi News`,
      seoDescription: t`Latest results and live tally for the 2023 Terengganu State Election. See the winners and who lost in real-time.`,
    },
    {
      key: "kedah",
      code: "KED",
      name: <Trans>Kedah</Trans>,
      seoTitle: t`2023 Kedah State Election Results - Lumi News`,
      seoDescription: t`Latest results and live tally for the 2023 Kedah State Election. See the winners and who lost in real-time.`,
    },
    {
      key: "negeri-sembilan",
      code: "NS",
      name: <Trans>Negeri Sembilan</Trans>,
      seoTitle: t`2023 Negeri Sembilan State Election Results - Lumi News`,
      seoDescription: t`Latest results and live tally for the 2023 Negeri Sembilan State Election. See the winners and who lost in real-time.`,
    },
  ];

  const [selectedState, setSelectedState] = useState("Overall");
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [countdownTime, setCountDownTime] = useState("");
  const [countdownEnd, setCountdownEnd] = useState(false);
  const [coalitions, setCoalitions] = useState<TCoalition[] | null>(null);
  const [states, setStates] = useState<TState[] | null>(null);
  const [modifiedStates, setModifiedStates] = useState<TSortedState[]>([]);
  const [completed, setCompleted] = useState(false);
  const [isNominated, setIsNominated] = useState(false);

  const locale = useLocaleContext();

  const prnFirebaseColID = process.env.REACT_APP_FB_COL_ID || "prn2023";

  const coalitionsQuery = query(
    collection(firestoreDB, prnFirebaseColID, "data", "coalitions")
  );
  const statesQuery = query(
    collection(firestoreDB, prnFirebaseColID, "data", "states")
  );

  const location = useLocation();

  useEffect(() => {
    const selectedTab = statesTab.find(
      (tab) => tab.key === location.pathname.split("/").pop()
    );
    if (!selectedTab) {
      setSelectedState("overall");
    } else {
      setSelectedState(selectedTab.key);
    }
  }, [selectedState, location]);

  useEffect(() => {
    const unsub = onSnapshot(
      doc(firestoreDB, prnFirebaseColID, "ui", "countdown", "countdown"),
      (doc) => {
        const milliseconds = doc?.data()?.startDateTime.seconds * 1000;
        const date = new Date(milliseconds);
        if (date > new Date()) {
          setCountdownEnd(false);
        } else {
          setCountdownEnd(true);
        }
        setCountDownTime(date.toString());
      }
    );
    const unsubCoalitions = onSnapshot(coalitionsQuery, (snapshot) => {
      const data: TCoalition[] = [];
      snapshot.forEach((doc) => {
        const docData = doc.data() as TCoalition;
        data.push(docData);
      });
      setCoalitions(data);
    });
    const unsubStates = onSnapshot(statesQuery, (snapshot) => {
      const data: TState[] = [];
      snapshot.forEach((doc) => {
        const docData = doc.data() as TState;
        const locale = statesTab.find(
          (state) => state.code === docData.stateCode
        );
        data.push({
          ...docData,
          stateNameLocale: locale?.name,
        });
      });
      setStates(data);
    });

    const unsubNomination = onSnapshot(
      doc(firestoreDB, prnFirebaseColID, "ui", "live", "live"),
      (doc) => {
        const nominated = doc?.data()?.isNominated;
        setIsNominated(nominated);
      }
    );

    return () => {
      unsubCoalitions();
      unsubStates();
      unsub();
      unsubNomination();
    };
  }, []);

  useEffect(() => {
    if (states) {
      const updatedStates = states?.map((state) => {
        const mappedCoalitions =
          Object.entries(state.competingCoalitions).length === 0
            ? null
            : Object.entries(state.competingCoalitions)
                .map(([key, value]) => {
                  const coalition = coalitions?.find(
                    (coalition) => coalition.id === key
                  );
                  if (coalition) {
                    return {
                      ...coalition,
                      value: parseInt(value),
                    };
                  }
                  return null;
                })
                .filter(Boolean); // Filter out null values

        const coalitionsOrderValue = coalitionsOrder?.[state?.stateCode] || {};

        const coalitionValues = Object.values(coalitionsOrderValue);
        const filteredCoalitions =
          coalitionValues
            .map((code) =>
              mappedCoalitions?.find((coalition) => coalition?.code === code)
            )
            .filter(Boolean) || [];
        const remainingCoalitions =
          mappedCoalitions
            ?.filter(
              (coalition) =>
                !coalitionValues.includes(coalition?.code as string)
            )
            .filter(Boolean) || [];

        return {
          ...state,
          mappedCoalitions: [
            ...filteredCoalitions,
            ...remainingCoalitions,
          ].sort((a, b) => {
            if (
              a !== null &&
              b !== null &&
              typeof a !== "undefined" &&
              typeof b !== "undefined"
            ) {
              return b.value - a.value;
            }
            return 0;
          }), // Sort by value in descending order,
        };
      });

      const sortedStates = updatedStates?.sort((a, b) => a.order - b.order);

      setModifiedStates(sortedStates as TSortedState[]);

      const allCompleted = sortedStates?.every(
        (state) => state.electionStatus === "completed"
      );

      setCompleted(allCompleted);
    }
  }, [states]);

  const metaImages: { [key: string]: string } = {
    en: "/state_elections.png",
    ms: "/state_elections_bm.png",
    zh: "/state_elections_chinese.png",
  };

  const topic = statesTab.find((state) => state.key === selectedState);

  const capitalizeFirstLetterWithoutHypen = (route: string) => {
    return route
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  // make sure there's a fallback if no topic found
  const { seoTitle, seoDescription } = topic || {
    seoTitle: t`2023 Malaysian State Election Results And News - Lumi News`,
    seoDescription: t`State election live tally, news, hot seats, candidates, and election campaigns on the Lumi News app. Stay up to date and get real-time 2023 state election results.`,
  };

  return (
    <>
      <ReactSEO
        seoTitle={seoTitle}
        seoDescription={seoDescription}
        seoImage={metaImages[locale as string]}
      />
      <div className="mx-auto max-w-7xl md:px-6 py-4 px-4">
        <div className="sticky top-0 z-10 bg-white py-4">
          <div className="relative">
            <div className="flex flex-wrap">
              {statesTab.map(({ name, key }) => (
                <Link
                  to={`${key}`}
                  className="mr-2 mb-2 md:mr-3 md:mb-3"
                  key={key}
                  onClick={() => {
                    setSelectedState(key);
                    ReactGA.event("prn2023_states", {
                      page_language: locale,
                      state_selected: key,
                    });
                  }}
                >
                  <div
                    className={`rounded-md md:rounded-xl ${
                      key === selectedState ? "bg-primary" : "bg-secondary"
                    } cursor-pointer px-2 py-1 md:py-2 md:px-4`}
                  >
                    <span
                      className={`flex whitespace-nowrap font-sen ${
                        key === selectedState ? "text-white" : "text-primary2"
                      } text-xs md:text-base`}
                    >
                      {name}
                    </span>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div className="lg:flex lg:flex-row lg:justify-around lg:space-x-8">
          <div className="lg:w-2/3">
            <div className="relative">
              {selectedState !== "overall" ? (
                <StateViewSection
                  setDialogOpen={setDialogOpen}
                  sortedState={
                    modifiedStates?.find(
                      (state: TState) =>
                        state.stateName ===
                        capitalizeFirstLetterWithoutHypen(selectedState)
                    ) as TSortedState
                  }
                />
              ) : (
                <>
                  <OverviewSection
                    setDialogOpen={setDialogOpen}
                    countdownTime={countdownTime}
                    setCountdownEnd={setCountdownEnd}
                    countdownEnd={countdownEnd}
                    sortedStates={modifiedStates as TSortedState[]}
                    allCompleted={completed as Boolean}
                  />
                  <div className="lg:w-1/3 mt-8 lg:mt-0 lg:hidden block">
                    <RightSection setDialogOpen={setDialogOpen} />
                  </div>
                  <TallyViewSection
                    sortedStates={modifiedStates?.slice(0, 2) as TSortedState[]}
                    countdownEnd={countdownEnd}
                    allCompleted={completed as Boolean}
                    coalitions={coalitions as TCoalition[]}
                    isNominated={isNominated}
                  />
                </>
              )}
              <div className="absolute inset-0 top-[80%] bottom-0 bg-gradient-to-b from-white/0 to-white"></div>
            </div>
            <div className="px-6">
              <QRContainer />
            </div>
          </div>
          <div className="lg:w-1/3 mt-8 lg:mt-0 hidden lg:block">
            <RightSection setDialogOpen={setDialogOpen} />
          </div>
        </div>
      </div>
      <PRNSwitchToAppDialog />
      <PRNPopUpDialog
        isDialogOpen={isDialogOpen}
        setDialogOpen={setDialogOpen}
      />
    </>
  );
};

export default LandingPage;
