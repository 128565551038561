export const COALITIONS: { [key: string]: { [key: string]: string } } = {
  BN: {
    en: "Barisan Nasional",
    ms: "Barisan Nasional",
    zh: "国阵",
  },
  PH: {
    en: "Pakatan Harapan",
    ms: "Pakatan Harapan",
    zh: "希盟",
  },
  "PH-BN": {
    en: "PH-BN\n(Perpaduan)",
    ms: "PH-BN\n(Perpaduan)",
    zh: "希盟-国阵\n(团结政府)",
  },
  PN: {
    en: "Perikatan Nasional",
    ms: "Perikatan Nasional",
    zh: "国盟",
  },
  PAS: {
    en: "PAS",
    ms: "PAS",
    zh: "伊党",
  },
  WARISAN: {
    en: "Warisan",
    ms: "Warisan",
    zh: "民兴党",
  },
  MUDA: {
    en: "MUDA",
    ms: "MUDA",
    zh: "民联阵",
  },
  PFP: {
    en: "Penang Front Party (PFP)",
    ms: "Parti Barisan Pulau Pinang (PFP)",
    zh: "前进党",
  },
  BEBAS: {
    en: "Independent",
    ms: "Bebas",
    zh: "独立人士",
  },
  PRM: {
    en: "Parti Rakyat Malaysia (PRM)",
    ms: "Parti Rakyat Malaysia (PRM)",
    zh: "人民党",
  },
  PUR: {
    en: "Parti Utama Rakyat (PUR)",
    ms: "Parti Utama Rakyat (PUR)",
    zh: "众民党",
  },
  PSM: {
    en: "Parti Sosialis Malaysia (PSM)",
    ms: "Parti Sosialis Malaysia (PSM)",
    zh: "社党",
  },
  OTHERS: {
    en: "Others",
    ms: "Lain",
    zh: "其他",
  },
};

export const coalitionsOrder: {
  [key: string]: string[];
} = {
  KED: ["PH-BN", "PN", "PRM", "BEBAS"],
  KEL: ["PH-BN", "PN", "PRM", "BEBAS"],
  NS: ["PH-BN", "PN", "MUDA", "BEBAS"],
  PEN: ["PH-BN", "PN", "MUDA", "PRM", "PFP", "BEBAS"],
  SEL: ["PH-BN", "PN", "MUDA", "PRM", "PSM", "PUR", "BEBAS"],
  TER: ["PH-BN", "PN", "MUDA", "BEBAS"],
};
